.navbar {
  display: grid;
  position: absolute;
  grid-template-columns: unset;
  width: min(50%, 275px);

  top: var(--nav-bar-height);

  text-transform: capitalize;
  font-size: var(--font-size-small);

  background-color: var(--background-color);
  opacity: 0;
  margin-left: var(--container-left-offset);
  box-shadow: var(--box-shadow-left-offset) 0px 0px rgba(0, 0, 0, 0.5);
  animation: none;
  right: -60%;
  isolation: isolate;
}

.open {
  right: 0;
  animation: slide-in var(--animation-duration) linear forwards;
  opacity: 1;
}

.navbar:not(.open) {
  right: -60%;
  animation: slide-box-shadow-in calc(var(--animation-duration) * 2) ease-in-out
    forwards;
}

.navbar.games:not(.open) {
  animation: slide-box-shadow-out calc(var(--animation-duration) * 2)
    ease-in-out forwards;
}
.navbar:not(.open):not(.games) {
  animation: slide-box-shadow-in calc(var(--animation-duration) * 2) ease-in-out
    forwards;
}

.button {
  --button-height: var(--nav-bar-height);
  --button-width: 100%;
  place-content: center;
  height: var(--button-height);
  width: var(--button-width);
  z-index: 5;
  background-color: var(--background-color);
  border: none;
  outline: none;
  cursor: pointer;
  margin-inline: var(--spacer-small);
  box-shadow: 3px 3px 10px rgb(0 0 0 / 50%) inset;
  animation: press-in var(--animation-duration) linear forwards;
}

.button-closed {
  box-shadow: none;
  animation: none;
}

.navlink {
  color: var(--font-color);
  padding: var(--spacer-large);
  width: 100%;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  transition: background-color 0.2s ease-in-out;
  font-weight: bold;
  font-size: var(--font-small);
}

.navlink:where(:focus, :hover, .active) {
  background-color: var(--secondary);
}

@media (min-width: 768px) {
  .navbar {
    --box-shadow-left-offset: var(--container-left-offset);
    grid-template-columns: repeat(var(--route-number), min-content);
    place-content: flex-end;
    place-items: flex-end;
    position: fixed;
    width: 100vw;
    top: 0;
    right: 0;
    opacity: 1;
    animation: slide-box-shadow-in calc(var(--animation-duration) * 2) ease-in
      forwards;
  }

  .open {
    right: 0;
    animation: inherit;
  }

  .navbar:not(.open) {
    opacity: 1;
    right: 0;
  }

  .navbar:not(.open):not(.games) {
    animation: slide-box-shadow-in calc(var(--animation-duration) * 2)
      ease-in-out forwards;
  }

  .games:not(.open) {
    animation: slide-box-shadow-out calc(var(--animation-duration) * 2)
      ease-in-out forwards;
  }

  .button {
    display: none;
  }

  .navlink {
    width: fit-content;
    padding: var(--spacer-small);
  }

  :where(.active)::after {
    content: "";
    display: block;
    height: 2px;
    background-color: var(--tertiary);
    animation: draw-line var(--animation-duration) ease-in-out both;
  }
}

@keyframes draw-line {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes slide-box-shadow-out {
  0% {
    box-shadow: var(--container-left-offset) 0px 10px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}

@keyframes slide-box-shadow-in {
  0% {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: var(--box-shadow-left-offset) 0px 10px rgba(0, 0, 0, 0.5);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    right: -60%;
  }
  25% {
    opacity: 1;
    right: -60%;
  }
  100% {
    right: 0;
  }
}

@keyframes slide-out {
  0% {
    right: 0;
  }
  100% {
    right: -60%;
  }
}

@keyframes press-in {
  0% {
    box-shadow: 0 rgb(0 0 0 / 50%) inset;
  }

  50% {
    box-shadow: 6px 6px 50px rgb(0 0 0 / 50%) inset;
  }

  100% {
    box-shadow: 3px 3px 10px rgb(0 0 0 / 50%) inset;
  }
}

@keyframes press-out {
  0% {
    box-shadow: 3px 3px 10px rgb(0 0 0 / 50%) inset;
  }

  100% {
    box-shadow: 0 rgb(0 0 0 / 50%) inset;
  }
}
