.aside {
  --container-height: 175px;
  --transform-start: transform: translateY(-100%);
  --transform-end:  transform: translateY(0);
  width: 100vw;
  min-height: var(--container-height);
  display: flex;
  z-index: 0;
  background-color: var(--background-color);
  color: var(--font-color);
  justify-content: space-around;
  text-align: left;
  animation: slide-in calc(var(--animation-duration) * 2) ease-in both;
  position: relative;
  box-shadow: none;
  flex-wrap: nowrap;
  margin: var(--nav-bar-height) 0;
}

.hidden {
  --transform-start: translateX(0);
  --transform-end: translateX(-100%);
  
  animation: slide-out var(--animation-duration) ease-in both;
}

.info-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  max-width: 80vw;
  display: flex;
  
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  letter-spacing: 1px;
}

.info h1 {
  font-size: var(--font-medium);
}

.info p {
  font-size: var(--font-small);
}

.info p:nth-of-type(1) {
  margin-bottom: var(--spacer-small);
}


.greeting {
  align-self: flex-start;
  display: none;
}

.hero {
  position: sticky;
  top: 0;
  display: flex;
  top: 0;
  flex-direction: column;
  align-items: flex-start;

  align-content: flex-start;
  justify-content: center;
}

.image {
  /* circular */
  border-radius: 50%;
  width: 175px;
  height: 175px;
  min-width: 175px;
  min-height: 175px;
  margin-inline: var(--spacer-medium);
  display: block;
  overflow: hidden;
}

@media (min-width: 768px) {
  
  .aside {
    --transform-start: translateX(-100%);
    --transform-end: translateX(0);
    
    --container-height: 110vh;
    width: var(--container-left-offset);
    padding-inline: var(--spacer-medium);
    margin-top: 0;
    position: fixed;
    flex-direction: column;
    align-items: center;
    gap: var(--spacer-small);
    top: -10%;
    justify-content: center;
    box-shadow: var(--box-shadow-left-offset) 0px 10px rgba(0, 0, 0, 0.5);
    animation: slide-in calc(var(--animation-duration) * 2) ease-in-out both;
    z-index: 2;
  }

  .hidden {
    --transform-start: translateX(0);
    --transform-end: translateX(-100%);
    
    animation: slide-out calc(var(--animation-duration) * 2) ease-in-out both;
  }

  .greeting {
    display: initial;
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: var(--transform-start);
  }

  100% {

    transform: var(--transform-end);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: var(--transform-start);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: var(--transform-end);
  }
}
