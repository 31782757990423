.projects {
  --container-height: fit-content;
  --container-width: calc(100vw - var(--container-left-offset));
  --background-color: var(--tertiary);
  --card-width: min(90vw, 50vmax);
  --card-height: min(90vw, 50vmax);
  height: fit-content;

  position: relative;
  display: flex;
  gap: var(--spacer-small);

  max-width: var(--container-width);
  width: calc(
    calc(var(--project-number) * var(--card-width)) +
      calc(var(--grid-gap) * calc(var(--project-number) + 1))
  );
  overflow: scroll;
}

.projects > div {
  min-width: var(--card-width);
  transform: scale(0.95);
}

.scroller {
  height: inherit;
}
.tech {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.visible {
  animation: slide-projects-in 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96)
    backwards;
  animation-delay: calc(var(--animation-order) * 150ms);
  animation-duration: 1000ms;
  transition: transform 200ms ease-out;
}

.projects > div:hover {
  transform: scale(1);
}

@keyframes slide-projects-in {
  0% {
    opacity: 0;
    /* transform: translateX(var(--spacer-medium)); */
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .projects {
    --card-height: min(90vw, 50vmin);
    --card-width: 35vmax;
    width: calc(
      calc(var(--project-number) * var(--card-width)) +
        calc(var(--grid-gap) * calc(var(--project-number) + 1))
    );
  }
}
