.header {
  padding-left: var(--spacer-xlarge);
}

.link {
  color: var(--tertiary);
  text-decoration: none;
  transition: color var(--animation-duration) ease-in-out;
  margin-left: auto;
  margin-right: var(--spacer-large);
}

.link:where(:hover)::after {
  content: "";
  display: block;
  height: 2px;
  background-color: var(--tertiary);
  animation: draw-line var(--animation-duration) ease-in-out both;
}

.introduction-container,
.about-container {
  --container-height: 60vh;
  --background-color: var(--tertiary);
  --font-color: var(--primary);
  height: var(--container-height);
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  padding: var(--spacer-xlarge);
}

:where(.introduction-container, .about-container) p {
  font-size: var(--font-small);
}

.introduction-container > p {
  height: auto;
}

.projects-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-large);
  margin-top: var(--spacer-xlarge);
  margin-bottom: var(--spacer-xlarge);
  position: relative;
}

.about-container {
  --container-height: 50vh;
  height: var(--container-height);
  text-align: left;
}

@media (min-width: 768px) {
  .technologies-container {
    --container-width: 100vw;
    --container-height: 50vh;
    flex-direction: row;
  }
  .technologies {
    --container-width: 20vw;
  }

  .scroller {
    --background-color: var(--primary);
  }

  .introduction-container,
  .about-container {
    --container-height: 60vh;
    --background-color: var(--tertiary);
    --font-color: var(--primary);
  }

  :where(.introduction-container, .about-container) p {
    font-size: var(--font-small);
    width: 80%;
  }
}

/* Style for projects container, which contains cards that scroll horizontally in a single row*/
@keyframes slide-text-in {
  0% {
    left: 30vw;
  }

  100% {
    left: var(--container-left-offset);
  }
}

@keyframes draw-line {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}
