.container {
  max-height: 100vh;
  height: var(--container-height);
  background: var(--background-color);
  color: var(--font-color);
  opacity: 0;
}

.visible {
  animation: fade-in 500ms ease-in forwards;
}

.invisible {
  animation: fade-out 500ms ease-in both;
}

@media screen and (min-width: 768px) {
  .container:first-child {
    margin-top: var(--nav-bar-height);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
