.card {
  background-color: var(--background-color);
  box-shadow: var(--box-shadow-left-offset) 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: var(--card-width);
  padding: var(--spacer-xlarge);

  color: black;
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  overflow-y: scroll;
}

.clickable {
  cursor: pointer;
}
