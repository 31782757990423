.game-selection-container {
  --card-width: min(80vw, 40vh);
  --card-height: min(80vw, 40vh);

  background-color: var(--primary);
  gap: var(--spacer-large);
  position: fixed;
  width: 100vw;
  height: 90vh;
  z-index: 4;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, var(--card-width));
  grid-template-rows: repeat(2, var(--card-width));
  grid-auto-flow: row;
  animation-delay: calc(150ms * var(--game-number));
  padding-top: var(--grid-gap);
  overflow: auto;
  scroll-padding-block-end: var(--spacer-large);
}

.card {
  transform: scale(0.95);
  transition: transform 200ms ease-out;
}

.card:hover {
  transform: scale(1);
}

.title {
  font-size: clamp(var(--font-size-large), 2vw, 2.5vw);
  font-weight: bold;
}

.page {
  --animation-duration: 150ms;
  position: absolute;
  max-height: calc(100vh - var(--nav-bar-height));
  overflow: hidden;
}

.game-container {
  --container-height: 100vh;
  background-color: var(--background-color);
  margin-top: 0;
  overflow-y: scroll;
  width: 100vw;
  opacity: 0;
}

.game-container > button {
  --button-height: 30px;
  --button-width: 30px;
  display: grid;
  place-content: center;
  height: var(--button-height);
  width: var(--button-width);
  border-radius: 50%;
  z-index: 5;
  background-color: var(--background-color);
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  position: absolute;
  top: var(--button-height);
  right: var(--button-height);
}

.game-container > button:hover {
  transform: scale(1.1);
}

.game-frame {
  transform: scale(0.85);
}

.game-selection-container.return:not(.invisible) {
  animation: slide-games-in-right 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96)
    forwards;
}

.card.return:not(.invisible) {
  animation: slide-games-in-right 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96)
    backwards;
  animation-delay: calc(
    calc(150ms / var(--animation-order)) * var(--game-number)
  );
}

.visible {
  animation: slide-games-in 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96) backwards;
  animation-delay: calc(var(--animation-order) * 200ms);
}

.invisible {
  animation: slide-games-out 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96) forwards;
  animation-delay: calc(var(--animation-order) * 150ms);
}

.game-selection-container.invisible {
  animation-delay: 500ms;
}

.show {
  animation: slide-games-in-left 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96)
    forwards;
  animation-delay: 500ms;
}

.hide {
  opacity: 0;
  animation: slide-games-out-right 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96)
    forwards;
}

@media screen and (min-width: 768px) {
  .game-selection-container {
    --card-width: min(500px, 50vh);
    --card-height: var(--card-width);
    grid-template-columns: repeat(auto-fit, var(--card-width));
    grid-template-rows: repeat(1, var(--card-width));
  }
}

@keyframes slide-games-in {
  0% {
    transform: translateX(250%) scale(0.95);
    opacity: 0;
    /* transform: translateX(var(--spacer-medium)); */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-games-in-right {
  0% {
    transform: translateX(-250%);
    opacity: 0;
    /* transform: translateX(var(--spacer-medium)); */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-games-out {
  0% {
    transform: translateX(0);
    opacity: 1;
    /* transform: translateX(var(--spacer-medium)); */
  }

  100% {
    transform: translateX(-250%);
    opacity: 0;
  }
}

@keyframes scale-in {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slide-games-in-left {
  0% {
    transform: translateX(250%);
    opacity: 0;
    /* transform: translateX(var(--spacer-medium)); */
  }

  50% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-games-out-right {
  0% {
    transform: translateX(0);
    opacity: 0;
    /* transform: translateX(var(--spacer-medium)); */
  }

  100% {
    transform: translateX(250%);
    opacity: 0;
  }
}
