.contact {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: var(--spacer-small);
  flex-wrap: wrap;
}

.icon {
  fill: #fff;
  height: fit-content;
}
