.layout {
  flex-direction: column;
  position: relative;
  height: 100vh;
  max-height: 100vh;
  --background-color: var(--primary);
  --font-color: var(--tertiary);
  overflow: hidden;
}

.header {
  --box-shadow-left-offset: 0;
  background-color: var(--primary);
  display: grid;
  position: fixed;
  height: var(--nav-bar-height);
  width: 100%;
  z-index: 1030;
  place-content: center;
  place-items: center;
  justify-content: flex-end;
  top: 0;
}

.scroller {
  background-color: var(--background-color);
}

.main {
  position: relative;
  display: flex;
  max-height: calc(100vh - var(--nav-bar-height));
  min-height: calc(100vh - var(--nav-bar-height));
  margin-top: var(--nav-bar-height);
  overflow-x: hidden;
  flex-direction: column;
  overflow-y: scroll;
}

.page {
  color: var(--font-color);
  /* page starts offsreen, and slides in from right */

  height: fit-content;
}

.page p {
  font-size: var(--font-small);
}

.page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6 {
  font-size: var(--font-medium);
}

.container {
  position: relative;
  max-height: 100vh;
  height: calc(var(--container-height) - var(--nav-bar-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  background: var(--background-color);
  color: var(--font-color);
  padding: 0 var(--spacer-small);
  margin: 0 auto;
  scroll-snap-align: start;
}

/* every odd container offset */
.container:nth-child(even) {
  left: 0vw;
}

.bg__color {
  --background-color: var(--primary);
  --font-color: var(--tertiary);
}

.bg__color--inverse {
  --font-color: var(--primary);
  --background-color: var(--tertiary);
}

@media (min-width: 768px) {
  .header {
    --box-shadow-left-offset: var(--container-left-offset);
  }

  .scroller {
    --background-color: var(--primary);
  }

  .page {
    --container-width: calc(100vw - var(--container-left-offset));
    margin-left: var(--container-left-offset);
  }
}

@keyframes slide-overlay-in {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: var(--container-left-offset);
  }
}

@keyframes slide-text-in {
  0% {
    left: 30vw;
  }
  50% {
    left: 30vw;
  }
  100% {
    left: calc(34vw + var(--spacer-medium));
  }
}
