*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
}

#root {
  display: contents;
}

body {
  /* Colors */
  --primary: #202124;
  --secondary: #35363a;
  --tertiary: rgb(255, 255, 255);
  --style-1: #00bcd4;
  --style-2: #009688;
  --style-3: #e91e63;

  /* Font Sizes */
  --font-base: clamp(1.2rem, 1.6vw, 2rem);
  --font-small: calc(var(--font-base) * 0.875);
  --font-medium: calc(var(--font-base) * 1.125);
  --font-large: calc(var(--font-base) * 1.375);
  --font-xlarge: calc(var(--font-base) * 1.625);
  /* Spacers */
  --spacer-base: clamp(1.2rem, 2vw, 2rem);
  --spacer-small: calc(var(--spacer-base) * 0.875);
  --spacer-medium: calc(var(--spacer-base) * 1);
  --spacer-large: calc(var(--spacer-base) * 1.5);
  --spacer-xlarge: calc(var(--spacer-base) * 2);
  --nav-bar-height: calc(var(--spacer-medium) * 2.625);
  --container-left-offset: 0vw;
  --container-right-offset: 0vw;
  --container-width: 100vw;
  --container-height: 90vh;
  /* Background */
  --background-color: var(--primary);
  --font-color: var(--tertiary);
  --card-width: min(550px, 80vw);
  --card-height: var(--card-width);

  --card-scale-factor: 1.05;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --grid-gap: calc(
    calc(calc(var(--card-width) * var(--card-scale-factor)) - var(--card-width)) *
      0.5
  );
  /* Animations */
  --animation-duration: 300ms;
  --transition-duration: 300ms;

  font-family: "Work Sans", sans-serif;
  font-size: var(--font-base);
  color: var(--font-color);
  background-color: var(--background-color);
  overflow-y: hidden;
  max-height: 100vh;

  /* Box Shadow */
  --box-shadow-left-offset: 0;
  --box-shadow-top-offset: 0;
  --box-shadow-blur: 10px;
  --box-shadow-color: rgba(0, 0, 0, 0.5);
  --box-shadow: var(--box-shadow-left-offset) var(--box-shadow-top-offset)
    var(--box-shadow-blur) var(--box-shadow-color);
}
/* Media Queries */
@media (min-width: 768px) {
  body {
    --container-left-offset: 27vw;
    --container-right-offset: 20vw;
    --container-width: 80vw;
    --container-height: 95vh;
  }
}

/* Prefers no motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0ms !important;
    transition-duration: 0ms !important;
    scroll-behavior: unset !important;
    scroll-snap-type: none !important;
  }
}
